<script setup lang="ts">
import { Ref } from 'vue'

const { value: learnMore } = useField('learnMore')
const state = inject('state') as Ref<any>
const formRef = inject('formRef')
const isMobile = useIsMobile()

// Only scroll on mobile devices
onMounted(() => {
  setTimeout(() => {
    // Only apply scrolling on mobile devices
    if (isMobile.value && formRef && formRef.value) {
      const element = formRef.value;
      const rect = element.getBoundingClientRect();
      // Only check if bottom is below viewport (simpler check)
      const isBottomVisible = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      
      // Only scroll if bottom of form is not visible
      if (!isBottomVisible) {
        const yOffset = -80;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, 100);
})
</script>

<template>
  <!-- Add FormProgress component at the top -->
  <div class="mb-6">
    <FormProgress class="!mb-6" />
  </div>

  <div class="text-2xl sm:text-3xl text-red text-center font-bold mb-8 px-2">
    Do you want to learn more about Medicare Advantage plans with flexible OTC
    allowance benefits of
    {{ state?.minOtcLtd ?? '$500' }}/year or more?
  </div>
  
  <div class="grid grid-cols-2 gap-4 max-w-md mx-auto">
    <Button 
      wide 
      size="lg" 
      type="submit" 
      variant="darkBlue"
      @click="learnMore = true" 
      class="font-bold text-xl sm:text-2xl"
    >
      YES
    </Button>
    <Button 
      wide 
      size="lg" 
      type="submit" 
      variant="darkBlue"
      @click="learnMore = false" 
      class="font-bold text-xl sm:text-2xl"
    >
      NO
    </Button>
    <Button
      wide
      size="lg"
      type="submit"
      variant="gray"
      class="col-span-2 font-medium text-xl"
      @click="learnMore = null"
    >
      SKIP
    </Button>
  </div>
</template>
