<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-red text-center"> Lo sentimos... </div>

  <div class="text-2xl sm:text-3xl text-center">
    Debes tener Medicare Partes A y B para inscribirte en un plan
    <span class="whitespace-nowrap">Medicare Advantage.</span>
  </div>
</template>
