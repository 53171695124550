export const maLoaded = ref(false)

onInit(({ isClient }) => {
  if (isClient) {
    const { load } = useScriptTag(
      'https://insurance.mediaalpha.com/js/serve.js',
      () => {
        maLoaded.value = true
      },
      {
        manual: true,
      }
    )
    load()
  }
})

export type MediaAlphaAd = {
  click_url: string
  ad_id: number
  display_url: string
  medium_image_url: string
  small_image_url: string
  description: string
  carrier: string
  large_image_url: string
  headline: string
}

export type AdsResponse = {
  ads: MediaAlphaAd[]
  num_ads: number
  time: number
  request_id: string
}
export type MediaAlphaAdCardType = MediaAlphaAd & {
  visible_display_url?: string
}
