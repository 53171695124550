<script setup lang="ts">
import { MediaAlphaAdCardType } from '~/modules/ma'

const props = defineProps<{
  ad?: MediaAlphaAdCardType
  shouldHideStar?: boolean
  highlight?: boolean
  index?: number
  black?: boolean
}>()

const contentImage = computed(() => {
  if (props.ad?.large_image_url) {
    return props.ad?.large_image_url
  } else if (props.ad?.medium_image_url) {
    return props.ad?.medium_image_url
  } else {
    return props.ad?.small_image_url
  }
})

const onClick = (e: MouseEvent) => {
  if ((e.target as HTMLElement).closest('[data-slot=description-toggle]')) {
    return
  }
  if (props.ad) {
    analytics.track('planClicked', {
      ad: props.ad,
    })
    window.open(props.ad.click_url, '_blank')
  }
}

const descriptionVisible = ref(false)
</script>

<template>
  <div
    v-if="ad"
    @click="onClick"
    :class="[
      'relative flex flex-col items-center gap-y-4 max-w-3xl cursor-pointer p-6',
      black
        ? 'bg-[#19212e] border border-zinc-500'
        : ['shadow', highlight ? 'bg-[#fffff5]' : 'bg-[#f9f9f9]'],
    ]"
  >
    <!-- star -->
    <div
      v-if="highlight"
      :class="[
        'absolute top-0 left-0 p-1.5 w-[60px] h-[60px] clip',
        black ? 'bg-[#facc14]' : 'bg-[#ffee36]',
      ]"
    >
      <Icon i-heroicons-solid:star class="w-6 h-6 text-white" />
    </div>

    <!-- image -->
    <div
      :class="[
        'border p-2.5 w-[150px] flex justify-center items-center bg-white',
        black ? 'border-zinc-500' : 'border-zinc-200',
      ]"
    >
      <img :src="contentImage" :alt="`${ad.carrier} Logo`" class="w-full" />
    </div>

    <!-- headline -->
    <h2
      v-html="ad.headline"
      :class="[
        'text-xl font-medium text-center',
        black ? 'text-white' : 'text-black',
      ]"
    />

    <!-- description toggle -->
    <button
      data-slot="description-toggle"
      @click="descriptionVisible = !descriptionVisible"
      class="relative w-full h-[25px] flex items-center justify-center"
    >
      <hr
        :class="[
          'absolute inset-x-0 top-1/2 h-px',
          black ? 'border-zinc-500' : 'border-[#777777]',
        ]"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
        width="25px"
        height="25px"
        :class="[
          'transition-transform duration-200 z-10',
          !descriptionVisible && 'rotate-180',
        ]"
      >
        <circle
          :fill="black ? '#ffffff' : '#777777'"
          cx="12.5"
          cy="12.5"
          r="12.5"
        />
        <path
          :fill="black ? '#000000' : '#ffffff'"
          d="M7.9,9.1l4.6,4.6l4.6-4.6l1.4,1.4l-6,6l-6-6L7.9,9.1z"
        />
      </svg>
    </button>

    <!-- description -->
    <div
      v-if="descriptionVisible"
      v-html="ad.description"
      :class="[
        'text-sm w-full pb-2',
        black ? 'text-zinc-300' : 'text-zinc-700',
      ]"
    />

    <!-- cta -->
    <div
      :class="[
        'text-center p-4 rounded-lg font-medium text-xl w-full',
        black ? 'bg-[#facc14] text-black' : 'bg-[#2581fe] text-white',
      ]"
    >
      View My Quote
    </div>
  </div>
</template>

<style scoped>
.clip {
  clip-path: polygon(0 0, 100% 0, 0 100%);
}
</style>
