<script setup lang="ts">
import type { Ref } from 'vue'

const lowBid = inject('lowBid') as Ref<boolean>

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const isPaidUser = ref(true)
const isMobile = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  isMobile.value = window.innerWidth < 640
})
</script>

<template>
  <template v-if="lowBid">
    <FormProgress class="!mb-6" />

    <div class="text-2xl sm:text-3xl text-red text-center">
      Congratulations!
    </div>

    <div class="text-3xl sm:text-4xl text-center">
      Speak with a licensed insurance agent and get answers to your questions
      about Medicare Advantage plans.
    </div>

    <MediaAlphaAds :user="{}" />
  </template>
  <template v-else>
    <FormProgress class="!mb-6" />

    <div class="text-2xl sm:text-3xl text-red text-center">
      Congratulations!
    </div>

    <div v-if="isPaidUser && isMobile" class="text-3xl sm:text-4xl text-center">
      Looks like you may qualify for $$$ back in premium reduction benefits!
    </div>
    <div v-else class="text-3xl sm:text-4xl text-center">
      Speak with a licensed insurance agent and get answers to your questions
      about Medicare Advantage plans.
    </div>

    <div v-if="isPaidUser && isMobile" class="text-3xl sm:text-4xl text-center">
      Call the {{ brand.name }}
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

    <div
      v-if="isPaidUser && isMobile"
      class="text-lg sm:text-xl font-medium text-center"
    >
      Act quickly: <span class="text-red"> {{ timer }} </span>
    </div>
  </template>
</template>
