<template>
  <div class="text-gray-600 text-center font-bold">
    With some Medicare Advantage Plans from carriers like Humana and
    UnitedHealthcare®
  </div>

  <FormButtonNext class="bg-gradient-to-b from-[#00b2ff] to-[#006aff]">
    Learn More
  </FormButtonNext>

  <div class="text-gray-600 text-center font-bold">
    Speak with a licensed insurance agent
  </div>
</template>
