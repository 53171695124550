<script setup lang="ts">
const giveBackValue = ref({})
const type = ref('giveback')
const selected = ref('STD')
const loading = ref(false)

const getData = async (zipCode: any) => {
  loading.value = true
  giveBackValue.value = await getGivebackSummaryV2({
    benefit_type: type.value,
    plan_type: type.value === 'otc' ? selected.value : undefined,
    zip_code: zipCode,
  })
  loading.value = false
  return
}
</script>

<template>
  <Layout class="container flex flex-col gap-4 my-8 mx-auto">
    <div class="max-w-40 flex flex-col gap-4">
      <select v-model="type" class="w-full">
        <option value="giveback">Giveback</option>
        <option value="otc">OTC</option>
      </select>

      <template v-if="type === 'otc'">
        <label>Plan Type:</label>
        <select v-model="selected" class="w-full">
          <option value="STD">STD</option>
          <option value="DSNP">DSNP</option>
          <option value="CSNP">CSNP</option>
        </select>
      </template>
    </div>

    <ZipCode @submit-zip-code="getData" button-label="Request" />

    <h2>Response:</h2>
    <div class="mt-4 flex flex-col gap-4" v-if="!loading">
      <pre
        v-if="JSON.stringify(giveBackValue) !== '{}'"
        class="font-semibold"
        >{{ giveBackValue }}</pre
      >
    </div>
    <div v-else class="mt-4">Loading...</div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "xsmid": {
      "m10": "xsmid-value-m10",
      "bh": "xsmid-value-bh"
    }
  }
}
</route>
