<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
import Button from '~/components/Button.vue'

const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)
const isMobile = useIsMobile()

const router = useRouter()

const show = ref(false)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (
    brand.id !== 'bridge' &&
    import.meta.env.MODE === 'production' &&
    (!isMobile.value || !isPaidUser.value)
  ) {
    router.push('/go-4-benefits')
    return
  }

  show.value = true
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0
  const top5Otc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
    top5Otc: top5Otc,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = 0
  setTimeout(() => {
    loading.value = false
    source.value = query.ad_value
      ? Number(query.ad_value) || 0
      : stateConfig.value?.minOtcLtd
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const queryAfid = ref<string | null>()

onMounted(() => {
  queryAfid.value = getSessionEnhanced().query.afid
})

const isMobileOrTablet = ref(true)

onMounted(() => {
  isMobileOrTablet.value = window.innerWidth < 1024
})

const destination = computed(() => {
  let domain = ''

  let path =
    isWorkingHours.value && isMobile.value
      ? '/additional-benefits-otc/call'
      : '/ads'

  const params = new URLSearchParams()

  params.set('black', 'true')

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = 'https://benefit-helpline.com'

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

analytics.page({
  name: 'Get Yours OTC spanish',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    v-if="show"
    :hide-header="true"
    :footer="brand.id === 'bridge' ? 'ss1' : '2025'"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> ¿Nuevo en Medicare? ¿Tiene Medicaid? </Banner>
    </template>
    <template v-if="brand.id !== 'bridge' || (isMobile && isPaidUser)">
      <div
        class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
      >
        <span
          class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
        >
          Ad
        </span>

        <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
          <div
            class="text-white text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center"
          >
            ¿YA OBTUVISTE TU
            <span class="text-6xl md:text-7xl text-yellow">
              {{ USDollarFormatter(0).format(output) }}/año+
            </span>
            DE ASIGNACIÓN OTC?
          </div>
          <div
            class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
          >
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'no',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                No
              </span>
            </Button>
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'yes',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                Sí
              </span>
            </Button>
          </div>

          <img
            src="../../assets/pharmacy-shelf.png"
            alt="Dos estanterías, una vacía y otra llena de remedios"
          />
        </div>
      </div>
      <div class="bg-black text-white">
        <div class="container space-y-2 py-2">
          <div class="text-sm text-center">
            ¿Cumples 65 años? Encuentra planes Medicare Advantage de
            aseguradoras como Humana, UnitedHealthcare®, Aetna.
          </div>
          <div class="text-center text-gray-400">
            MULTI-PLAN_HLDAD1124299_M
          </div>
        </div>
      </div>
    </template>
    <div class="container space-y-8 !max-w-3xl py-8">
      <h1 class="text-4xl font-bold">
        Navegando por la Elegibilidad de Adultos Mayores para la Asignación de
        Medicamentos Sin Receta (OTC) de Medicare
      </h1>
      <p class="text-lg">
        El acceso a productos esenciales de salud y bienestar es crucial para
        los adultos mayores, y muchos planes Medicare Advantage ofrecen una
        <strong>Asignación para Productos Sin Receta (OTC)</strong> para ayudar
        a cubrir estos costos. Esta guía proporciona información sobre cómo las
        personas elegibles pueden utilizar este beneficio para ahorrar dinero en
        artículos de cuidado de la salud cotidianos.
      </p>
      <h2 class="text-2xl font-bold">
        Entendiendo la Asignación OTC de Medicare
      </h2>
      <p class="text-lg">
        Medicare es el programa federal de seguro médico de EE.UU. que sirve
        principalmente a personas de 65 años o más, así como a ciertos
        individuos más jóvenes con discapacidades calificadas o condiciones de
        salud graves. Mientras que Medicare Original (Partes A y B) no cubre
        productos de salud sin receta de uso rutinario, algunos
        <strong>Planes Medicare Advantage (Parte C)</strong> proporcionan una
        asignación OTC, permitiendo a los inscritos comprar artículos aprobados
        con poco o ningún costo.
      </p>
      <h2 class="text-2xl font-bold">
        ¿Qué es la Asignación OTC de Medicare?
      </h2>
      <p class="text-lg">
        La <strong>Asignación OTC de Medicare</strong> es un beneficio ofrecido
        por algunos <strong>Planes Medicare Advantage</strong>, que permite a
        los miembros comprar
        <strong>productos relacionados con la salud</strong> sin pagar de su
        bolsillo. Este beneficio proporciona una cantidad específica de
        dólares—mensual, trimestral o anual—que puede usarse en productos sin
        receta elegibles, como:
      </p>
      <ul class="list-disc pl-8 text-lg">
        <li> Medicamentos para resfriados y gripe </li>
        <li> Analgésicos </li>
        <li> Suministros de primeros auxilios </li>
        <li> Vitaminas y suplementos </li>
        <li> Productos para el cuidado dental y ocular </li>
        <li> Artículos de incontinencia e higiene personal </li>
      </ul>
      <h2 class="text-2xl font-bold"> Cómo Funciona la Asignación OTC </h2>
      <p class="text-lg">
        Los inscritos en Medicare Advantage reciben una tarjeta de beneficios
        OTC o un catálogo con artículos y minoristas aprobados donde pueden
        realizar compras. Dependiendo del plan, los adultos mayores pueden:
      </p>
      <ul class="list-disc pl-8 text-lg">
        <li>
          <strong>Comprar en tienda</strong> en farmacias y minoristas
          participantes
        </li>
        <li>
          <strong>Hacer pedidos en línea</strong> a través del sitio web OTC de
          un plan
        </li>
        <li>
          <strong>Llamar y realizar un pedido</strong> para entrega a domicilio
        </li>
      </ul>
      <p class="text-lg">
        Los saldos no utilizados pueden o no transferirse al siguiente período
        de beneficios, por lo que es importante utilizar la asignación dentro
        del plazo establecido.
      </p>
      <h2 class="text-2xl font-bold"> Criterios Clave para la Elegibilidad </h2>
      <ol class="list-decimal pl-8 text-lg space-y-2">
        <li>
          <strong
            >Inscripción en un Plan Medicare Advantage con Beneficios
            OTC</strong
          ><br />
          Este beneficio solo está disponible a través de
          <strong>Planes Medicare Advantage</strong> que incluyen cobertura OTC.
          Dado que no todos los planes lo ofrecen, los adultos mayores deben
          comparar los detalles de los planes para encontrar la mejor opción.
        </li>
        <li>
          <strong>Residir en el Área de Servicio de un Plan</strong><br />
          Los Planes Medicare Advantage son específicos de cada región, y los
          beneficios OTC pueden variar según la ubicación. Los adultos mayores
          deben confirmar la disponibilidad en su área antes de inscribirse.
        </li>
        <li>
          <strong>Seguir las Pautas Específicas del Plan</strong><br />
          Cada plan tiene diferentes límites de gasto, listas de productos
          elegibles y minoristas designados. Entender estos detalles asegura que
          los inscritos maximicen sus beneficios.
        </li>
      </ol>
      <h2 class="text-2xl font-bold">Maximizando sus Beneficios OTC</h2>
      <ul class="list-disc pl-8 text-lg space-y-2">
        <li>
          <strong>Revise su Catálogo OTC Regularmente</strong><br />
          Los Planes Medicare Advantage actualizan periódicamente su lista de
          productos OTC elegibles. Revisar el catálogo asegura que aproveche al
          máximo los artículos disponibles.
        </li>
        <li>
          <strong>Use la Asignación Completa Antes de que Expire</strong><br />
          Algunos planes no permiten que los fondos se acumulen, lo que
          significa que cualquier saldo no utilizado puede perderse al final del
          período de beneficios.
        </li>
        <li>
          <strong>Compare Planes Medicare Advantage</strong><br />
          Dado que las asignaciones OTC varían entre planes, comparar diferentes
          opciones de Medicare Advantage durante el
          <strong>Período de Inscripción Anual</strong> puede ayudar a los
          adultos mayores a encontrar la cobertura más beneficiosa.
        </li>
      </ul>
      <p class="text-lg">
        Para muchos adultos mayores, la
        <strong>Asignación OTC de Medicare</strong> proporciona una manera fácil
        de ahorrar en artículos esenciales de salud y bienestar cotidianos. Al
        comprender los requisitos de elegibilidad y cómo usar la asignación de
        manera efectiva, los miembros de Medicare Advantage pueden reducir los
        costos de su bolsillo y mantener una mejor salud general.
      </p>
      <Button variant="undefined" :to="destination" class="border-0">
        <img
          src="../../assets/simple-shop-plans-es.png"
          width="614"
          height="300"
          alt="Un anuncio sobre planes Medicare Advantage"
        />
      </Button>
      <hr class="border-zinc-300" />
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}

.custom-transition {
  transition: width 0.5s;
}

.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}

@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }

  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "bridge"],
    "robots": "noindex"
  }
}
</route>
