<script setup lang="ts">
const props = defineProps<{
  placementId?: string
  user: Record<string, any>
}>()

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 5000 })

  const zipCode = props.user.zip || ip.lookup?.postalCode || query.zip || 'auto'

  const user = _.omitBy(
    {
      ...props.user,
      zip: zipCode,
    },
    (value) => value === null || value === undefined
  )

  if (user.phone) {
    user.phone = `(${user.phone.slice(0, 3)}) ${user.phone.slice(4)}`
  }

  const MediaAlphaExchange: any = {
    data: user,
    placement_id: props.placementId
      ? props.placementId
      : query.utm_medium?.toLowerCase() === 'aff'
      ? 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
      : 'MpZ2mW9mJJuQkIdjR-182eQEblVQFQ',
    type: 'ad_unit',
    version: 17,
    ua_class: 'auto',
  }

  if (xsmid.value) MediaAlphaExchange.cms_smids = xsmid.value
  if (query.afid) MediaAlphaExchange.sub_1 = query.afid
  if (segment.anonymousId) MediaAlphaExchange.sub_2 = segment.anonymousId
  MediaAlphaExchange.sub_3 = getSession().id

  until(maLoaded)
    .toBe(true)
    .then(() => {
      window.MediaAlphaExchange = MediaAlphaExchange
      window.MediaAlphaExchange__load('mediaalpha_placeholder')
    })
})

const handleClick = (event: MouseEvent) => {
  let currEl: HTMLElement | null = event.target as HTMLElement

  while (currEl) {
    if (currEl.classList.contains('mav-partner')) {
      analytics.track('maAdClicked', {
        dataset: { ...currEl.dataset },
      })
      return
    }
    currEl = currEl.parentElement
  }
}
</script>

<template>
  <div id="mediaalpha_placeholder" @click="handleClick">
    <Spinner class="mx-auto" />
  </div>
</template>

<style>
#mediaalpha_placeholder > div {
  padding: 0 !important;
}
</style>
