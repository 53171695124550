import { ArticleType } from './articles'

export type CarrierResponse = {
  percent_change_last_year: number
  enrollments: number
  id: number
  liked: boolean
  carrier: string
}

export type Carrier = {
  change: number
  enrollments: string
  id: number
  liked: boolean
  name: string
  numberOfEnrollments: number
}

export type CarrierCardType = {
  id: string
  carrier_group_name: string
  name: string
  logo: string
  rating: number
  callNumber?: string
  allowanceCard?: string
  overview?: string
  selectedTabId?: string
  cta: {
    label: string
    url: string
    subtitle: string
  }
  benefitsList: string[]
  contentSection?: ArticleType[]
}

export type CarrierOTCSummaryData = {
  carrier_group: string
  plans:
    | { rank: number; amount: number; plan_id: string; stars: number }[]
    | null
  retailers_or_vendors: string[] | null
  types_of_retailers: string[] | null
  plan_count: number
  min_amount: string
  max_amount: string
  stars: number
}

export type PlanAverageCost = {
  location: string
  zip: string
  costRange: string
}

export const getCarriers = async (stateCode: string): Promise<Carrier[]> => {
  const { data } = await axios.get<any>(
    import.meta.env.VITE_WEBSITE_API_URL + `/enrollments?state=${stateCode}`
  )

  const formattedCarriers: Array<Carrier> = data.map(
    (carrier: CarrierResponse) => {
      return {
        id: carrier.id,
        name: carrier.carrier,
        enrollments: new Intl.NumberFormat('en-US').format(carrier.enrollments),
        change: carrier.percent_change_last_year,
        liked: false,
      }
    }
  )

  return formattedCarriers
}

type BaseCarriersOTCSummariesParams = {
  carriers?: string[]
  planTypes?: string[]
}

type CarriersOTCSummariesParamsWithStateCode =
  BaseCarriersOTCSummariesParams & {
    stateCode: string | null
    zipCode?: string
  }

type CarriersOTCSummariesParamsWithZipCode = BaseCarriersOTCSummariesParams & {
  stateCode?: string
  zipCode: string | null
}

export type CarriersOTCSummariesParams =
  | CarriersOTCSummariesParamsWithStateCode
  | CarriersOTCSummariesParamsWithZipCode

export const getCarriersOTCSummaries = async ({
  stateCode,
  zipCode,
  carriers,
  planTypes,
}: CarriersOTCSummariesParams): Promise<CarrierOTCSummaryData[]> => {
  let url = import.meta.env.VITE_WEBSITE_API_URL + `/carriers/otc_summary`
  let params = new URLSearchParams()
  if (stateCode) {
    params.append('state', stateCode)
  }
  if (zipCode) {
    params.append('zip_code', zipCode)
  }
  if (carriers && carriers.length > 0) {
    carriers.forEach((carrier) => {
      params.append('carriers_groups[]', carrier)
    })
  }
  if (planTypes && planTypes.length > 0) {
    planTypes.forEach((plan) => {
      params.append('plan_types[]', plan)
    })
  }

  const finalUrl = url + '?' + params.toString()

  const { data } = await axios.get<any>(finalUrl)
  return data
}

export const joinWithAnd = (arr: string[]) =>
  arr.length === 0
    ? ''
    : arr.length === 1
    ? arr[0]
    : arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1)

export const joinWithY = (arr: string[]) =>
  arr.length === 0
    ? ''
    : arr.length === 1
    ? arr[0]
    : arr.slice(0, -1).join(', ') + ' y ' + arr.slice(-1)
