<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const isMobile = useIsMobile()
const router = useRouter()

const show = ref(false)
const loading = ref(true)
const source = ref(0)
const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (
    brand.id !== 'bridge' &&
    import.meta.env.MODE === 'production' &&
    (!isMobile.value || !isPaidUser.value)
  ) {
    router.push('/go-4-benefits')
    return
  }

  enterExperiment('getYoursX4cEsNewHeadline')

  show.value = true
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0
  const top5Otc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
    top5Otc: top5Otc,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = 0
  setTimeout(() => {
    loading.value = false
    source.value = query.ad_value
      ? Number(query.ad_value) || 0
      : stateConfig.value?.minOtcLtd
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const destination = computed(() => {
  let domain = ''

  const path =
    isWorkingHours.value && isMobile.value && isPaidUser.value
      ? '/additional-benefits-grocery-2024-es/call-now'
      : '/ads'

  const params = new URLSearchParams()

  if (path === '/ads') {
    params.set('black', 'true')
  }

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = `https://benefit-helpline.com`

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

analytics.page({
  name: 'Allowance Card Bridge x4c Spanish',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    v-if="show"
    :footer="brand.id === 'bridge' ? 'ss1' : '2025'"
    :hide-header="true"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> ¿Nuevo en Medicare? ¿Tiene Medicaid? </Banner>
    </template>
    <template v-if="brand.id !== 'bridge' || (isMobile && isPaidUser)">
      <div
        class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
      >
        <span
          class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
        >
          Ad
        </span>

        <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
          <div
            class="text-white text-center text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center"
          >
            <template v-if="exp?.getYoursX4cEsNewHeadline?.default">
              ¿YA OBTUVISTE TU
              <span class="text-6xl md:text-7xl text-yellow">
                {{ USDollarFormatter(0).format(output) }}/año+
              </span>
              ASIGNACIÓN?
            </template>
            <template v-else>
              ¿Ya tienes tu tarjeta para groceries de
              <br />
              <span class="text-yellow">
                <span class="text-6xl md:text-7xl">
                  {{ USDollarFormatter(0).format(output) }}
                </span>
                <br />
                al año o más?
              </span>
            </template>
          </div>
          <div
            class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
          >
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'no',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                No
              </span>
            </Button>
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'yes',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                Sí
              </span>
            </Button>
          </div>

          <img
            src="../../assets/fridge.png"
            alt="Un refrigerador lleno de comida"
          />
        </div>
      </div>
      <div class="bg-black text-white">
        <div class="container space-y-2 py-2">
          <div class="text-sm text-center">
            ¿Cumples 65 años? Encuentra planes Medicare Advantage de
            aseguradoras como Humana, UnitedHealthcare®, Aetna.
          </div>
          <div class="text-center text-gray-400">
            MULTI-PLAN_HLDAD1124299_M
          </div>
        </div>
      </div>
    </template>
    <div
      class="container space-y-8 !max-w-3xl py-8"
      :class="{ 'mt-8': brand.id === 'bridge' && !isMobile }"
    >
      <h1 class="text-4xl font-bold">
        ¡Adultos Mayores: No Se Pierdan Estos Increíbles Programas de Asistencia
        para Comestibles!
      </h1>
      <h2>
        ¡Nutra Sus Años Dorados—Explore Programas Esenciales de Asistencia para
        Comestibles Solo Para Usted!
      </h2>
      <img
        src="../../assets/emily-grocery-2.png"
        width="716"
        height="464"
        alt="Emily en un estacionamiento sosteniendo bolsas de comestibles"
      />

      <p class="text-lg">
        ¿Cree que la asistencia para comestibles es solo para aquellos en
        extrema necesidad? ¡Piénselo de nuevo! Incluso si se está manejando
        bien, estos programas pueden marcar una gran diferencia, ayudándole a
        ahorrar dinero para otras necesidades esenciales mientras se asegura de
        tener alimentos nutritivos. La realidad es que usted ha trabajado duro
        toda su vida y merece disfrutar de sus años dorados sin preocuparse por
        los costos de los comestibles. Desde los beneficios de SNAP hasta Meals
        on Wheels, estos programas están diseñados para mantenerlo saludable,
        feliz y próspero, independientemente de su nivel de ingresos.
      </p>

      <h3> 1. Programa de Asistencia Nutricional Suplementaria (SNAP) </h3>
      <p class="text-lg">
        SNAP es el programa de ayuda nutricional federal más grande, que ofrece
        a los adultos mayores elegibles un beneficio mensual para comprar
        comestibles. La cantidad del beneficio está determinada por sus
        ingresos, el tamaño de su hogar y otros factores. Puede usar estos
        beneficios para comprar productos frescos, carnes, productos lácteos y
        más en minoristas autorizados.
      </p>

      <h3> 2. Comidas sobre Ruedas (Meals on Wheels)</h3>
      <p class="text-lg">
        Meals on Wheels entrega comidas calientes y nutritivas directamente a su
        puerta, asegurándole obtener los nutrientes diarios necesarios. Este
        servicio es especialmente útil si tiene movilidad limitada o le resulta
        difícil cocinar. Muchos programas locales también brindan servicios
        adicionales, como controles de seguridad y visitas sociales.
      </p>

      <h3> 3. El Programa de Asistencia Alimentaria de Emergencia (TEFAP)</h3>
      <p class="text-lg">
        TEFAP ofrece apoyo alimentario de emergencia a personas de bajos
        ingresos, incluidos los adultos mayores. A través de TEFAP, puede
        acceder a alimentos gratuitos como productos enlatados, pasta y otros
        productos básicos de la despensa distribuidos por los bancos de
        alimentos locales y las organizaciones comunitarias.
      </p>

      <h3>
        4. Programa de Nutrición del Mercado de Agricultores para Personas
        Mayores (SFMNP)
      </h3>
      <p class="text-lg">
        Este programa proporciona a las personas mayores elegibles cupones que
        se pueden usar para comprar frutas, verduras y hierbas frescas en los
        mercados de agricultores, puestos de carretera y ubicaciones de
        agricultura apoyada por la comunidad. Es una excelente manera de
        disfrutar de productos frescos de temporada mientras apoya a los
        agricultores locales.
      </p>

      <h3>
        5. Programa de Alimentos Suplementarios de Productos Básicos (CSFP)
      </h3>
      <p class="text-lg">
        CSFP ofrece paquetes de alimentos mensuales a adultos mayores de bajos
        ingresos, llenos de artículos nutritivos como cereales, frutas y
        verduras enlatadas y proteínas. Estos paquetes están diseñados para
        complementar su dieta con nutrientes esenciales que de otra manera no
        estaría obteniendo.
      </p>
      <p class="text-lg">
        Estos programas tienen como objetivo ayudarle a mantener una dieta
        saludable y mejorar su bienestar general. No dude en solicitar o
        comunicarse con sus servicios comunitarios locales para obtener ayuda;
        ¡se lo ha ganado!
      </p>

      <Button variant="undefined" :to="destination" class="border-0">
        <img
          src="../../assets/simple-shop-plans-es.png"
          width="614"
          height="300"
          alt="Un anuncio sobre planes Medicare Advantage"
        />
      </Button>
      <hr class="border-zinc-300" />
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}
.custom-transition {
  transition: width 0.5s;
}
.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}
@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }

  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "bridge", "m10"],
    "robots": "noindex",
    "language": "spanish"
  }
}
</route>
