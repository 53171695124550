<script setup lang="ts">
import { Ref } from 'vue'
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)

const state = inject('state') as Ref<any>
// We'll keep this but not use it in the template
const allowanceBenefits = ref<string>('')

const isProd = import.meta.env.MODE === 'production'
const isMobile = useIsMobile()

const formRef = inject('formRef')

onMounted(async () => {
  error.value = false
  try {
    plans.value = await getPlans({
      stateCode: state.value?.code,
      carrier_groups: [],
      amount: 10000,
      starting_from: 0,
      benefits: ['otc_summary'],
    })
    plans.value = plans.value.sort((planA, planB) => {
      return planB.premium - planA.premium
    })
    // Still populate this in case we need it later, but we're not using it in the template
    allowanceBenefits.value = plans.value[0].benefits
      .map((benefit) => benefit.name)
      .join(', ')

    // Only scroll on mobile devices
    setTimeout(() => {
      // Only apply scrolling on mobile devices
      if (isMobile.value && formRef && formRef.value) {
        const element = formRef.value;
        const rect = element.getBoundingClientRect();
        // Only check if bottom is below viewport (simpler check)
        const isBottomVisible = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
        
        // Only scroll if bottom of form is not visible
        if (!isBottomVisible) {
          const yOffset = -80;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
    }, 100);
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <template v-if="loading && !error">
    <div class="flex items-center justify-center p-6">
      <Spinner />
    </div>
  </template>
  
  <template v-else-if="!loading && error">
    <div class="text-xl text-red-600 text-center font-bold mb-4">
      There's some errors while trying to find plans for you
    </div>
    <div class="text-lg text-center font-medium mb-6">
      Your licensed
      <span class="whitespace-nowrap">Insurance Agent</span>
      is ready to help
    </div>
    <div class="mb-6">
      <ClickToCall :props="{ ctcLocation: 'last-step-error' }">
        <template #text>
          Click to Call
          <br />
          TTY 711, 24/7
        </template>
      </ClickToCall>
    </div>
  </template>
  
  <template v-else>
    <!-- We found plans near you with {{ allowanceBenefits }} allowance benefits. -->
    <div class="text-xl text-red text-center font-bold mb-4">
      We found plans near you with OTC allowance benefits.
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>

    <div class="text-xl text-center font-medium mt-4">
      <span class="flex items-center justify-center gap-2">
        <div class="flex w-4 h-4 items-center justify-center self-start mt-[0.4em]">
          <div
            class="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-green-400 opacity-75"
          ></div>
          <div class="inline-flex rounded-full h-4 w-4 bg-green-500"></div>
        </div>
        <span class="flex flex-col leading-tight">
          <span>LICENSED INSURANCE</span>
          <span>AGENT READY</span>
        </span>
      </span>
    </div>
  </template>
</template>
