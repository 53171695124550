<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const isMobile = useIsMobile()
const router = useRouter()
const route = useRoute()

const loading = ref(true)
const source = ref(0)
const carriers = ref('Humana and UnitedHealthcare®')
const isPaidUser = ref(true)
const show = ref(false)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (
    brand.id !== 'bridge' &&
    import.meta.env.MODE === 'production' &&
    (!isMobile.value || !isPaidUser.value)
  ) {
    router.push('/go-4-benefits')
    return
  }

  show.value = true
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const state = states.find((st) => st.code === ip.lookup?.stateCode)

  const zip = route.query.zip_code
    ? Array.isArray(route.query.zip_code)
      ? (route.query.zip_code.pop() as string)
      : (route.query.zip_code as string)
    : ip.lookup?.postalCode || ''

  let benefitSummary

  if (zip) {
    try {
      benefitSummary = await getGivebackSummaryV2({
        zip_code: zip,
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (benefitSummary) {
    carriers.value = joinWithAnd(benefitSummary.matching_carriers)
  }

  const minGiveback = benefitSummary?.min_giveback
    ? Number(
        benefitSummary.benefit_period === 'per month'
          ? benefitSummary.min_giveback * 12
          : benefitSummary.min_giveback
      )
    : 0

  source.value = 0

  setTimeout(() => {
    loading.value = false

    source.value = Math.min(
      minGiveback ||
        Number(state?.maxFipsMinGivebackLtdAmount) ||
        state?.minGiveback ||
        500,
      2096
    )
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const destination = computed(() => {
  let domain = ''

  let path =
    isWorkingHours.value && isMobile.value && isPaidUser.value
      ? '/additional-benefits-buyback-es/call'
      : '/ads'

  const params = new URLSearchParams()

  params.set('black', 'true')

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = 'https://benefit-helpline.com'

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

analytics.page({
  name: 'Get Your Social Security ss1 spanish',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout
    v-if="show"
    :hide-header="true"
    :footer="brand.id === 'bridge' ? 'ss1' : '2025'"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> ¿Nuevo en Medicare? ¿Tiene Medicaid? </Banner>
    </template>
    <template v-if="brand.id !== 'bridge' || (isMobile && isPaidUser)">
      <div
        class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
      >
        <span
          class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
        >
          Ad
        </span>

        <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
          <div
            class="text-white text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center"
          >
            ¿Ya recuperaste tus
            <span class="text-6xl md:text-7xl text-yellow">
              {{ USDollarFormatter(0).format(output) }} o más al año
            </span>
            de regreso en tu Seguro Social?
          </div>

          <div
            class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
          >
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'no',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                No
              </span>
            </Button>
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'yes',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                Sí
              </span>
            </Button>
          </div>

          <img
            src="../../assets/retirement-balance-es.png"
            alt="Dos balances de jubilación, el de la izquierda con 'Antes' y una lista de depósitos de $0 y el segundo con 'Después' con varios depósitos de $174"
          />
        </div>
      </div>

      <div class="bg-black text-white">
        <div class="container space-y-2 py-2">
          <div class="text-sm text-center">
            ¿Nuevo en Medicare? Descubra un plan Medicare Advantage de
            aseguradoras reconocidas como {{ carriers }}
          </div>
          <div class="text-center text-gray-400">
            MULTI-PLAN_HLDAD1224324_M
          </div>
        </div>
      </div>
    </template>

    <div class="container space-y-8 !max-w-3xl py-8">
      <h1 class="text-4xl font-bold">
        Guía para la Elegibilidad de Adultos Mayores para el Reembolso de
        Medicare Parte B
      </h1>
      <p class="text-lg">
        Reducir los costos de atención médica es una prioridad para muchos
        adultos mayores, y un beneficio valioso que puede ayudar es el Reembolso
        de Medicare Parte B. Esta guía explica cómo las personas elegibles
        pueden aprovechar este programa y reducir sus gastos de Medicare.
      </p>
      <h3 class="text-2xl font-bold"
        >Entendiendo el Reembolso de Medicare Parte B</h3
      >
      <p class="text-lg">
        Medicare, el programa federal de seguro médico de EE.UU., sirve
        principalmente a personas de 65 años o más, junto con algunas personas
        más jóvenes con discapacidades o condiciones de salud graves. Mientras
        que Medicare Original requiere que los inscritos paguen una prima
        estándar de la Parte B, ciertos Planes Medicare Advantage ofrecen un
        Reembolso de la Parte B, que puede ayudar a reducir estos costos
        mensuales.
      </p>
      <h3 class="text-2xl font-bold"
        >¿Qué es el Reembolso de Medicare Parte B?</h3
      >
      <p class="text-lg">
        El Reembolso de Medicare Parte B, también conocido como reducción de la
        prima de la Parte B, es un beneficio ofrecido por algunos planes
        Medicare Advantage (Medicare Parte C). Estos planes, proporcionados por
        aseguradoras privadas aprobadas por Medicare, pueden reembolsar una
        parte de la prima de la Parte B del inscrito directamente a través de su
        cheque del Seguro Social. Este beneficio ayuda a reducir los gastos de
        bolsillo sin reducir la cobertura médica.
      </p>
      <h3 class="text-2xl font-bold"
        >Cómo Funciona el Reembolso de la Parte B</h3
      >
      <p class="text-lg">
        Los adultos mayores que se inscriben en un Plan Medicare Advantage con
        el Reembolso de la Parte B continúan recibiendo todos los beneficios de
        Medicare Partes A y B, junto con beneficios adicionales como cobertura
        dental, de visión y de medicamentos recetados. La diferencia clave es
        que el proveedor de seguros cubre parte de la prima de Medicare Parte B,
        reduciendo lo que el inscrito tiene que pagar. La cantidad del reembolso
        varía dependiendo del plan y la aseguradora.
      </p>
      <h3 class="text-2xl font-bold"
        >Requisitos de Elegibilidad para el Reembolso</h3
      >
      <ol class="list-decimal pl-8 text-lg space-y-2">
        <li>
          <strong>Inscripción en Medicare Partes A y B</strong><br />
          Para calificar para el reembolso de la Parte B, los adultos mayores
          deben estar inscritos tanto en Medicare Parte A como en Parte B. No
          todos los planes ofrecen este beneficio, por lo que es importante
          comparar opciones cuidadosamente.
        </li>
        <li>
          <strong>Responsabilidad de Pagar la Prima de la Parte B</strong><br />
          Las personas que reciben asistencia financiera completa de Medicaid u
          otros programas que ya cubren su prima de la Parte B pueden no ser
          elegibles para el reembolso. Este beneficio está diseñado para
          aquellos que son responsables de pagar su prima de la Parte B de su
          bolsillo.
        </li>
        <li>
          <strong>Vivir en el Área de Servicio de un Plan</strong><br />
          Los Planes Medicare Advantage con el Reembolso de la Parte B solo
          están disponibles en ciertas regiones. Los adultos mayores deben
          residir en un área donde se ofrezca un plan participante para ser
          elegibles para la reducción de la prima.
        </li>
      </ol>
      <h3 class="text-2xl font-bold">Maximizando Sus Ahorros</h3>
      <ul class="list-disc pl-8 text-lg space-y-2">
        <li>
          <strong>Compare Opciones de Planes Anualmente</strong><br />
          Los Planes Medicare Advantage, incluidos aquellos que ofrecen el
          Reembolso de la Parte B, pueden cambiar cada año. Revisar los planes
          disponibles durante el Período de Inscripción Anual de Medicare
          asegura que los adultos mayores seleccionen la mejor cobertura con el
          mayor potencial de ahorro.
        </li>
        <li>
          <strong>Consulte con Expertos en Medicare</strong><br />
          Hablar con un especialista en Medicare puede ayudar a aclarar qué
          planes ofrecen el beneficio de reembolso y determinar la opción más
          rentable basada en las necesidades individuales de atención médica.
        </li>
        <li>
          <strong>Verifique la Cantidad del Reembolso</strong><br />
          La cantidad del Reembolso de la Parte B varía según el plan y la
          ubicación. Consultar con el proveedor de seguros ayudará a confirmar
          cuánto de la prima será reembolsada y cómo se aplicará.
        </li>
      </ul>
      <p class="text-lg">
        Para los adultos mayores que buscan reducir sus costos de Medicare, el
        Reembolso de la Parte B es un beneficio valioso que puede reducir los
        gastos mensuales mientras mantiene cobertura completa. Al entender los
        requisitos de elegibilidad y comparar opciones de planes, los adultos
        mayores pueden aprovechar esta oportunidad de ahorro y administrar mejor
        sus costos de atención médica.
      </p>
      <Button variant="undefined" :to="destination" class="border-0">
        <img
          src="../../assets/simple-shop-plans-es.png"
          width="614"
          height="300"
          alt="Un anuncio sobre planes Medicare Advantage"
        />
      </Button>
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}
.custom-transition {
  transition: width 0.5s;
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "language": "spanish",
    "robots": "noindex"
  }
}
</route>
