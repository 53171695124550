<script setup lang="ts">
import { getGivebackSummaryV2 } from '~/modules/benefits'

const route = useRoute()
const blackVersion = route.query.black === 'true'

const giveback = ref('$500')
const carriers = ref('Humana y UnitedHealthcare®')

const isPaidUser = ref(true)
const isMobile = useIsMobile()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const state = states.find((st) => st.code === ip.lookup?.stateCode)

  const zip = route.query.zip_code
    ? Array.isArray(route.query.zip_code)
      ? (route.query.zip_code.pop() as string)
      : (route.query.zip_code as string)
    : ip.lookup?.postalCode || ''

  let benefitSummary

  if (zip) {
    try {
      benefitSummary = await getGivebackSummaryV2({
        zip_code: zip,
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (benefitSummary) {
    carriers.value = joinWithY(benefitSummary.matching_carriers)
  }

  const minGiveback = benefitSummary?.min_giveback
    ? Number(
        benefitSummary.benefit_period === 'per month'
          ? benefitSummary.min_giveback * 12
          : benefitSummary.min_giveback
      )
    : 0

  giveback.value = USDollarFormatter(0).format(
    Math.min(
      minGiveback ||
        Number(state?.maxFipsMinGivebackLtdAmount) ||
        state?.minGiveback ||
        500,
      2096
    )
  )
})

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)

const isProd = import.meta.env.MODE === 'production'
</script>

<template>
  <template v-if="blackVersion && isPaidUser && isMobile">
    <div
      class="max-w-3xl mx-auto space-y-4 pt-0 pb-6 h-full bg-black text-white"
    >
      <div class="text-xl sm:text-3xl font-bold text-center">
        Algunos miembros de Medicare en {{ ip.lookup?.stateName || 'América' }}
        <span :class="'text-[#FACC14]'">
          pueden calificar para {{ giveback }}/año o más de reducción en la
          prima de la Parte B
        </span>
        con algunos planes Medicare Advantage.
      </div>

      <img
        src="../../assets/retirement-balance.png"
        alt="Dos balances de jubilación, el de la izquierda con 'Antes' y una lista de depósitos de $0 y el segundo con 'Después' con varios depósitos de $174"
        width="1080"
        height="1080"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div :class="['text-3xl sm:text-4xl text-center', 'font-semibold']">
        Parece que
        <span class="text-[#FACC14]">
          puedes calificar para {{ giveback }} al año
        </span>
        en reducciones de prima de la Parte B.
      </div>

      <div class="relative">
        <img
          src="../../assets/yellow-arrow.gif"
          class="absolute right-0 -top-14 h-12 w-auto"
        />

        <ClickToCall
          :props="{ ctcLocation: 'last-step' }"
          v-slot="{ handleClick }"
        >
          <Button
            :to="phone.uri"
            @click="handleClick"
            variant="undefined"
            :class="[
              'flex flex-col items-center justify-center',
              'animate-custom-ping w-full p-4 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full',
              'shadow-[0px_0px_0px_12px_#fff] p-4',
            ]"
          >
            <span
              class="flex text-start justify-center items-center h-full text-[#080B12] text-lg"
            >
              <Icon
                i-heroicons-solid:phone
                class="w-10 h-10 animate-shake mr-3"
              />
              <span class="flex flex-col items-start">
                <span class="font-bold"> LLAMA {{ phone.formatted }} </span>
                <span class="font-normal">TTY 711</span>
              </span>
            </span>
          </Button>
        </ClickToCall>
      </div>

      <div v-if="isProd" class="text-lg sm:text-xl font-medium text-center">
        Actúa rápido:
        <span class="px-2 py-1 bg-red text-white rounded">
          {{ timer }}
        </span>
      </div>
      <div class="text-sm text-center text-white">
        ¿Nuevo en Medicare? Descubre un plan Medicare Advantage de aseguradoras
        reconocidas como {{ carriers }}
      </div>
    </div>
  </template>
  <template v-else>
    <FormProgress class="!mb-6" />

    <div class="text-2xl sm:text-3xl text-center">
      <span class="text-red">¡Excelente!</span>
      Encontramos planes Medicare Advantage en
      <span class="whitespace-nowrap">tu área!</span>
    </div>

    <div class="text-xl sm:text-2xl text-center">
      Llama para hablar con un
      <span class="whitespace-nowrap">agente de seguros autorizado</span>
    </div>

    <div class="space-y-2">
      <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
    </div>
  </template>
</template>

<style scoped>
.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}
@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }
  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>
