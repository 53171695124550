<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
import { AdsResponse, MediaAlphaAd } from '~/modules/ma'
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>([])
const loading = ref(false)
const error = ref(false)
const startingFrom = ref(0)
const loadMoreVisible = ref(true)

const zipCode = computed(() => {
  return forms.search4Otc2024?.zipcode ?? query.zip
})

type LoadDataParams = {
  startingFrom?: number
  amount?: number
}

const loadData = async (params?: LoadDataParams) => {
  const startingFrom = params?.startingFrom ? params.startingFrom : 0
  const amount = params?.amount ? params.amount : 10

  error.value = false
  loading.value = true
  try {
    const responsePlans = await getPlans({
      zipCode: zipCode.value,
      starting_from: startingFrom,
      amount: amount,
    })

    if (responsePlans.length === 0) {
      loadMoreVisible.value = false
      return
    }

    plans.value = [...plans.value, ...responsePlans]
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
}

const source = ref(0)

const ads = ref<MediaAlphaAd[]>([])
const currentAdsIndex = ref(0)

onMounted(async () => {
  loadData({ startingFrom: 0, amount: 10 })
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value =
    states.find((state) => state.code === ip.lookup?.stateCode)?.maxOtc ?? 1000
  try {
    const url = import.meta.env.VITE_WEBSITE_API_URL + `/mediaalpha/ads`

    const body = {
      data: {},
      ip: ip.lookup?.value,
      local_hour: new Date().getHours(),
      placement_id: 'loyhRoUY8koY5vZgUSMZtFFQKTVnPg',
      ua: navigator.userAgent,
      ua_language: navigator.language,
      url: location.href,
      version: 17,
      sub_3: getSession().id,
    }
    const response = await axios.post<AdsResponse>(url, body)
    ads.value = response?.data.ads
  } catch (e) {
    console.error('Error on retrieve ads', (e as any).toString())
  } finally {
    loading.value = false
  }
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const nextPlans = () => {
  startingFrom.value += 10
  loadData({ startingFrom: startingFrom.value })
  currentAdsIndex.value =
    ads.value.length - 1 > currentAdsIndex.value
      ? currentAdsIndex.value + 1
      : currentAdsIndex.value
}
</script>

<template>
  <div class="text-3xl sm:text-4xl text-center">
    <div v-if="!loading && !error" class="text-3xl sm:text-4xl text-center">
      Good news, we found plans with grocery, over the counter up to<br /><span
        class="text-5xl text-red font-semibold"
      >
        {{ USDollarFormatter(0).format(output) }}</span
      ><br />
      or flexible spending allowance benefits near you.
    </div>
  </div>
  <div v-if="!loading && error" class="text-3xl sm:text-4xl text-center">
    We have some issues with our servers. Please try again later.
    <Button @click="() => loadData({ startingFrom: 0, amount: 10 })"
      >Try Again</Button
    >
  </div>
  <div
    class="flex flex-col gap-4 border border-gray-300 rounded-md py-3 px-4 shadow-md bg-white"
    v-for="plan in plans"
    :key="plan.plan_id"
  >
    <!-- <div v-if="county?.state" class="text-lg font-medium flex gap-4">
      <span class="font-semibold">County:</span>
      <span class="font-medium"> {{ county.name }}</span>
    </div> -->
    <div v-if="ip.lookup?.stateCode" class="text-lg font-medium flex gap-4">
      <span class="font-semibold">State:</span>
      <span class="font-medium"> {{ ip.lookup?.stateCode }}</span>
    </div>

    <div class="text-lg font-medium"> {{ plan.carrier }} </div>
    <div class="pl-3 space-y-4">
      <span class="text-gray-600"> {{ plan.plan_name }} </span>
      <span class="w-full flex items-stretch">
        <span>{{ plan.enrollments }} members (i)</span>
        <span
          :class="[
            plan.growth >= 0 ? 'text-green-600' : 'text-orange-600',
            'text-green-600',
            'inline-block ml-2',
          ]"
        >
          {{ (plan.growth >= 0 ? '+' : '') + plan.growth + '%' }}
        </span>
      </span>
    </div>
    <div class="ẗext-lg font-semibold"> Benefits </div>
    <div class="grid grid-cols-2 gap-4 text-start">
      <div
        class="flex items-center gap-4"
        v-for="benefit in plan.benefits"
        :key="benefit.name"
      >
        <Icon
          v-if="benefit.name !== 'Dental'"
          i-heroicons-outline:check
          class="text-blue"
        />
        <Icon
          v-if="benefit.name === 'Dental' && !benefit.unavailable"
          i-heroicons-outline:check
          class="text-blue"
        />
        <Icon
          v-if="benefit.name === 'Dental' && benefit.unavailable"
          i-heroicons-outline:x
          class="text-red"
        />
        <div class="flex-1 text-lg"> {{ benefit.name }} </div>
      </div>
    </div>
  </div>
  <div v-if="loading" class="p-8">
    <Spinner class="mx-auto" />
  </div>
  <Button wide v-if="loadMoreVisible && !loading" @click="nextPlans">
    Load More
  </Button>
  <MediaAlphaSingleAd
    v-if="ads.length > 0"
    :ad="ads[currentAdsIndex]"
    :key="ads[currentAdsIndex].ad_id"
    :should-hide-star="false"
  />
</template>
