<template>
  <FormProgress class="!mb-6" />

  <div class="text-xl sm:text-2xl text-center font-medium">
    Continue below to compare plans in your area.
  </div>

  <div class="min-h-md">
    <MediaAlphaAds :user="{}" />
  </div>
</template>
