<script setup lang="ts">
import MediaAlphaAdCard2 from '~/components/MediaAlphaAdCard2.vue'
import Spinner from '~/components/Spinner.vue'
import { AdsResponse, MediaAlphaAdCardType } from '~/modules/ma'

const isMobile = useIsMobile()
const route = useRoute()

const blackVersion = computed(() => {
  return route.query.black === 'true'
})

const loading = ref(false)
const ads = ref<MediaAlphaAdCardType[]>([])

const parseUrl = (item: { display_url: string }): string => {
  let { display_url } = item

  display_url = display_url.trim()

  if (display_url.startsWith('//')) {
    return `https:${display_url}`
  }

  if (
    !display_url.startsWith('http://') &&
    !display_url.startsWith('https://')
  ) {
    return `https://${display_url}`
  }
  return display_url
}

onMounted(async () => {
  loading.value = true

  await until(() => ip.lookup).toBeTruthy()

  try {
    const response = await axios.post<AdsResponse>(
      import.meta.env.VITE_WEBSITE_API_URL + `/mediaalpha/ads`,
      {
        data: {},
        ip: ip.lookup?.value,
        local_hour: new Date().getHours(),
        placement_id: 'MpZ2mW9mJJuQkIdjR-182eQEblVQFQ',
        ua: navigator.userAgent,
        ua_language: navigator.language,
        url: location.href,
        version: 17,
        sub_1: getSessionEnhanced().query.afid,
        sub_2: segment.anonymousId,
        sub_3: getSession().id,
      }
    )

    ads.value = response?.data.ads.map((ad) => {
      const displayUrl = ad.display_url
      if (displayUrl) {
        const validUrl = parseUrl(ad)
        return {
          ...ad,
          display_url: validUrl,
          visible_display_url: ad.display_url,
        }
      }
      return ad
    })
  } catch (error) {
    console.error(error)
  }

  loading.value = false
})
</script>

<template>
  <Layout
    :hide-header="isMobile || !isWorkingHours"
    :header="blackVersion ? 'simpleBlack' : undefined"
    footer="2024"
    :class="blackVersion ? 'bg-black text-white' : ''"
  >
    <template #banner>
      <Banner> New to Medicare? On Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6 px-4">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Click 2 or More Options
      </div>
      <div class="max-w-3xl mx-auto space-y-6">
        <Spinner v-if="loading" class="mx-auto" />
        <template v-else>
          <MediaAlphaAdCard2
            v-for="(ad, index) of ads"
            :ad="ad"
            :index="index"
            :highlight="index === 0"
            :black="blackVersion"
          />
        </template>
      </div>
    </div>
    <div v-if="!blackVersion" class="h-px bg-gray-200" />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10", "med10", "bridge"],
    "robots": "noindex"
  }
}
</route>
