<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-center"> ¿Dónde vives? </div>

  <Field
    type="zip"
    name="zipcode"
    placeholder="Ingresa tu código postal (opcional)"
    class="text-center"
  />

  <FormButtonNext> Continuar </FormButtonNext>
</template>
