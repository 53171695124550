<script setup lang="ts">
useHead({
  title: `Beneficios adicionales de reembolso - ${brand.name}`,
})

const route = useRoute()
const blackVersion = route.query.black === 'true'

const isPaidUser = ref(true)
const isMobile = useIsMobile()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const state = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)
})
</script>

<template>
  <Layout
    :header="blackVersion ? 'simpleBlack' : 'simple3'"
    :hideHeader="blackVersion || isMobile"
    footer="2025"
    :class="blackVersion ? 'bg-black text-white' : ''"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> ¿Nuevo en Medicare? ¿Cumpliendo 65 años? ¿Mudándose? </Banner>
    </template>
    <div class="container">
      <div class="max-w-3xl mx-auto space-y-6 py-6">
        <template v-if="!blackVersion">
          <div class="text-xl sm:text-3xl font-semibold text-center">
            Algunos estadounidenses con Medicare A&B en
            {{ ip.lookup?.stateName || 'América' }}
            están obteniendo planes Medicare Advantage con
            <span class="text-red">
              beneficios adicionales como OTC, gastos flexibles,
              <span class="whitespace-nowrap">o dental.</span>
            </span>
          </div>

          <img
            src="../../assets/buyback-2024.jpeg"
            width="1200"
            height="627"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
        </template>

        <div
          class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0 relative"
        >
          <UseForm v-slot="{ values }">
            <Form
              id="buyback-es"
              class="py-6 px-4 sm:px-6 !max-w-3xl"
              scrollBehavior="auto"
              :steps="[
                { id: 'medicareab' },
                { id: 'sorry', if: values?.haveMedicare === false },
                { id: 'medicaid' },
                { id: 'zipcode' },
                { id: 'call' },
              ]"
              product="medicare"
            />
          </UseForm>
        </div>

        <template v-if="!blackVersion">
          <div class="text-lg text-center">
            Compara planes de aseguradoras como: Humana, UnitedHealthcare®.
          </div>

          <div v-if="state" class="text-center">
            Mostrando resultados para {{ state.name }}
          </div>
        </template>
      </div>
      <div class="h-px bg-gray-200"></div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "language": "spanish",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624201_M",
      "m10": "MULTI-PLAN_HLWEB0624180_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0624201_M",
      "m10": "MULTI-PLAN_HLWEB0624180_M"
    }
  }
}
</route>
