<script setup lang="ts">
import type { Ref } from 'vue'

const { onSubmit } = inject<any>('form')
const formRef = inject('formRef')
const isMobile = useIsMobile()

// Only scroll on mobile devices
onMounted(() => {
  setTimeout(() => {
    // Only apply scrolling on mobile devices
    if (isMobile.value && formRef && formRef.value) {
      const element = formRef.value;
      const rect = element.getBoundingClientRect();
      // Only check if bottom is below viewport (simpler check)
      const isBottomVisible = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      
      // Only scroll if bottom of form is not visible
      if (!isBottomVisible) {
        const yOffset = -80;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, 100);
})

useTimeoutFn(() => {
  onSubmit()
}, 2000)
</script>

<template>
  <FormProgress class="!mb-6" />

  <div class="text-2xl sm:text-3xl text-center font-bold mb-8">
    Searching for a licensed insurance agent in your area...
  </div>

  <div class="flex justify-center">
    <Spinner class="w-12 h-12" />
  </div>
</template>
