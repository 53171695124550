<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const isMobile = useIsMobile()
const router = useRouter()
const route = useRoute()

const isPaidUser = ref(true)
const loading = ref(true)
const source = ref(0)
const show = ref(false)
const carriers = ref('Humana and UnitedHealthcare®')

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (
    brand.id !== 'bridge' &&
    import.meta.env.MODE === 'production' &&
    (!isMobile.value || !isPaidUser.value)
  ) {
    router.push('/go-4-benefits')
    return
  }

  show.value = true
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const state = states.find((st) => st.code === ip.lookup?.stateCode)

  const zip = route.query.zip_code
    ? Array.isArray(route.query.zip_code)
      ? (route.query.zip_code.pop() as string)
      : (route.query.zip_code as string)
    : ip.lookup?.postalCode || ''

  let benefitSummary

  if (zip) {
    try {
      benefitSummary = await getGivebackSummaryV2({
        zip_code: zip,
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (benefitSummary) {
    carriers.value = joinWithAnd(benefitSummary.matching_carriers)
  }

  const minGiveback = benefitSummary?.min_giveback
    ? Number(
        benefitSummary.benefit_period === 'per month'
          ? benefitSummary.min_giveback * 12
          : benefitSummary.min_giveback
      )
    : 0

  source.value = 0

  setTimeout(() => {
    loading.value = false

    source.value = Math.min(
      minGiveback ||
        Number(state?.maxFipsMinGivebackLtdAmount) ||
        state?.minGiveback ||
        500,
      2096
    )
  }, 500)
})

const destination = computed(() => {
  let value = isPaidUser.value
    ? '/additional-benefits-buyback-2024/call-now'
    : '/additional-benefits-buyback-2024'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeOutExpo,
})

analytics.page({
  name: 'Buyback Bridge 1',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    v-if="show"
    :hide-header="true"
    :footer="brand.id === 'bridge' ? 'ss1' : '2024'"
  >
    <template v-if="brand.id !== 'bridge' || (isMobile && isPaidUser)">
      <div
        class="bg-[#508E2D] flex flex-col min-h-[100vh] p-4 gap-6 md:gap-12 font-dm items-center relative"
      >
        <span
          class="absolute text-xs text-gray-200 top-2 right-2 border border-gray-200 p-1 rounded"
        >
          Ad
        </span>
        <span
          v-if="loading"
          class="inset-0 flex items-center justify-center font-dm"
        >
          <Spinner />
        </span>
        <div
          v-if="!loading"
          :class="[
            'container h-full flex-grow min-h-[calc(100vh-320px)] flex',
            'gap-8 text-xl sm:text-3xl font-bold flex flex-col text-white md:items-center',
          ]"
        >
          <span
            class="text-3xl mt-10 md:text-7xl text-white font-bold inline-block font-dm flex gap-2 flex-col md:text-center md:gap-8 md:justify-evenly md:items-center uppercase"
          >
            <div class="flex flex-row items-center gap-4 md:gap-8 flex-grow">
              <span
                class="text-yellow-300 text-5xl md:text-7xl w-36 flex gap-2 md:items-center md:justify-center md:w-64"
              >
                $ {{ output.toFixed(0) }}
              </span>
              <span>/ yr</span>
            </div>
            or more of part b premium reduction
          </span>
          <div class="image-wrapper">
            <img src="../../assets/buyback-coin-jar-2.gif" class="image" />
          </div>
          <span class="text-sm -mt-6 w-dull text-center"
            >with some Medicare Advantage Plans</span
          >
          <div
            class="text-lg md:max-w-3xl md:w-full flex my-4 w-full justify-self-end"
          >
            <Button
              :to="destination"
              class="font-sans bg-yellow-300 !text-[#508E2D]"
              wide
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                })
              "
            >
              <span> Learn More </span>
              <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
            </Button>
          </div>
        </div>
      </div>

      <div class="bg-[#508E2D] text-white">
        <div class="container space-y-2 py-2">
          <div class="text-sm text-center">
            New to Medicare? Discover a Medicare Advantage plan from well known
            carriers like {{ carriers }}
          </div>
          <div class="text-center text-gray-200">
            MULTI-PLAN_HLDAD1224324_M
          </div>
        </div>
      </div>
    </template>

    <div class="container space-y-8 !max-w-3xl py-8">
      <h1 class="text-4xl font-bold">
        Navigating Senior Eligibility for Medicare Part B Premium Reduction
      </h1>
      <p class="text-lg">
        Reducing healthcare costs is a priority for many seniors, and one
        valuable benefit that can help is the Medicare Part B Premium Reduction
        (also called "giveback" benefit). This guide explains how eligible
        individuals can take advantage of this program and reduce their Medicare
        expenses.
      </p>
      <h3 class="text-2xl font-bold">
        Understanding Medicare Part B Premium Reduction
      </h3>
      <p class="text-lg">
        Medicare, the U.S. federal health insurance program, primarily serves
        individuals aged 65 and older, along with some younger people with
        disabilities or serious health conditions. While Original Medicare
        requires enrollees to pay a standard Part B premium, certain Medicare
        Advantage Plans offer a Part B Premium Reduction, which can help lower
        these monthly costs.
      </p>
      <h3 class="text-2xl font-bold">
        What is Medicare Part B Premium Reduction?
      </h3>
      <p class="text-lg">
        Medicare Part B Premium Reduction, also known as Part B premium
        giveback, is a benefit offered through some Medicare Advantage (Medicare
        Part C) plans. These plans, provided by private insurers approved by
        Medicare, can reimburse a portion of the enrollee's Part B premium
        directly through their Social Security check. This benefit helps reduce
        out-of-pocket expenses without reducing medical coverage.
      </p>
      <h3 class="text-2xl font-bold"> How Part B Premium Reduction Works </h3>
      <p class="text-lg">
        Seniors who enroll in a Medicare Advantage Plan with Part B Premium
        Reduction continue to receive all benefits of Medicare Parts A and B,
        along with additional benefits like dental, vision, and prescription
        drug coverage. The key difference is that the insurance provider covers
        part of the Medicare Part B premium, reducing what the enrollee has to
        pay. The amount of the reduction varies depending on the plan and
        insurance carrier.
      </p>
      <h3 class="text-2xl font-bold">
        Eligibility Requirements for Premium Reduction
      </h3>
      <ol class="list-decimal pl-8 text-lg space-y-2">
        <li>
          <strong>Enrollment in Medicare Parts A and B</strong><br />
          To qualify for Part B premium reduction, seniors must be enrolled in
          both Medicare Part A and Part B. Not all plans offer this benefit, so
          it's important to compare options carefully.
        </li>
        <li>
          <strong>Responsibility for Paying Part B Premium</strong><br />
          Individuals who receive full financial assistance from Medicaid or
          other programs that already cover their Part B premium might not be
          eligible for the reduction. This benefit is designed for those who are
          responsible for paying their Part B premium out of pocket.
        </li>
        <li>
          <strong>Living in a Plan's Service Area</strong><br />
          Medicare Advantage Plans with Part B Premium Reduction are only
          available in certain regions. Seniors must reside in an area where a
          participating plan is offered to be eligible for the premium
          reduction.
        </li>
      </ol>
      <h3 class="text-2xl font-bold">Maximizing Your Savings</h3>
      <ul class="list-disc pl-8 text-lg space-y-2">
        <li>
          <strong>Compare Plan Options Annually</strong><br />
          Medicare Advantage Plans, including those offering Part B Premium
          Reduction, can change each year. Reviewing available plans during
          Medicare's Annual Enrollment Period ensures seniors select the best
          coverage with the greatest potential for savings.
        </li>
        <li>
          <strong>Consult with Medicare Experts</strong><br />
          Speaking with a Medicare specialist can help clarify which plans offer
          the reduction benefit and determine the most cost-effective option
          based on individual healthcare needs.
        </li>
        <li>
          <strong>Verify Reduction Amount</strong><br />
          The amount of Part B Premium Reduction varies by plan and location.
          Checking with the insurance provider will help confirm how much of the
          premium will be reimbursed and how it will be applied.
        </li>
      </ul>
      <p class="text-lg">
        For seniors looking to reduce their Medicare costs, Part B Premium
        Reduction is a valuable benefit that can lower monthly expenses while
        maintaining full coverage. By understanding eligibility requirements and
        comparing plan options, seniors can take advantage of this savings
        opportunity and better manage their healthcare costs.
      </p>
      <Button variant="undefined" :to="destination" class="border-0">
        <img
          src="../../assets/simple-shop-plans.png"
          width="614"
          height="300"
          alt="An ad about Medicare Advantage plans"
        />
      </Button>
      <hr class="border-zinc-300" />
    </div>
  </Layout>
</template>

<style scoped>
.image-wrapper {
  @apply w-full md:w-[520px] md:h-[292px] mx-auto border-[#A5DA63] overflow-hidden md-auto md:border-8;
  @apply rounded-xl flex-grow-0;
}
.image {
  @apply: md:w-full;
  @media screen and (min-width: 768px) {
    transform: scale(1.08) translateY(9px);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "benefits": ["giveback"]
  }
}
</route>
