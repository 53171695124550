<script setup lang="ts">
useHead({
  title: `Beneficios adicionales en comestibles 2024 - ${brand.name}`,
})

const notProd = import.meta.env.MODE !== 'production'
const isProd = import.meta.env.MODE === 'production'

const isMounted = useMounted()

const maxOtc = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.maxOtc
})
const minOtc = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc
})
const minOtcLtd = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = ref(true)

onMounted(() => {
  isMobile.value = window.innerWidth < 640
})

const showDefaultExperience = computed(() => {
  return !isMobile.value && import.meta.env.MODE === 'production'
})

const route = useRoute()
const blackVersion = route.path.indexOf('call-now') >= 0
</script>

<template>
  <Layout
    :header="blackVersion ? 'simpleBlack' : 'simple3'"
    :hideHeader="isMobile"
    footer="grocery2024es"
    :class="blackVersion ? 'bg-black text-white' : 'container'"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> ¿Cumpliendo 65 años? ¿En Medicaid? </Banner>
    </template>

    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        <template v-if="showDefaultExperience">
          ¿Estás buscando planes de seguro de Medicare? Un agente de seguros con
          licencia puede verificar tu elegibilidad.
        </template>
        <template v-else-if="notProd">
          ¿Tiene Medicare? ¿Está por cumplir 65 años? Algunos miembros de
          Medicare en {{ ip.lookup?.stateName || 'America' }} pueden calificar
          para recibir
          <span :class="blackVersion ? 'text-[#FACC14]' : 'text-red'">
            {{ minOtc ? `$${minOtc} al año o más` : 'una asignación' }}
            para comprar alimentos
          </span>
          con algunos planes Medicare Advantage.
        </template>
        <template v-else>
          Algunos miembros de Medicare en
          {{ ip.lookup?.stateName || 'America' }} pueden calificar para recibir
          <span :class="blackVersion ? 'text-[#FACC14]' : 'text-red'">
            {{ minOtcLtd ? `$${minOtcLtd} o más` : 'una' }}
            asignación para comprar alimentos
          </span>
          con algunos planes Medicare Advantage.
        </template>
      </div>

      <template v-if="isMounted">
        <img
          v-if="!isMobile || !isPaidUser"
          src="../../assets/helpline-seniors-hands-up.png"
          width="1254"
          height="836"
          class="w-[90%] sm:w-[400px] mx-auto"
        />
        <img
          v-else-if="
            query.utm_source?.toLowerCase() === 'google' ||
            query.utm_source?.toLowerCase() === 'facebook'
          "
          src="../../assets/fruits-black-card.png"
          width="1100"
          height="576"
          class="w-[90%] sm:w-[400px] mx-auto"
        />
        <img
          v-else
          src="../../assets/allowance-card-4.png"
          width="504"
          height="331"
          class="w-[90%] sm:w-[400px] mx-auto"
        />
      </template>

      <UseForm v-slot="{ values }">
        <Form
          id="grocery2024es"
          class="!max-w-3xl"
          scrollBehavior="auto"
          :steps="[
            { id: 'medicareab' },
            { id: 'medicaid' },
            { id: 'us', if: notProd },
            { id: 'wantGrocery', if: isPaidUser },
            { id: 'loading' },
            { id: 'call', if: isProd ? isMobile : true },
            { id: 'clicks', if: isProd ? !isMobile : false },
            { id: 'call-now', if: false },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
    <div v-if="!blackVersion" class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10", "boomer"],
    "smid": "MULTI-PLAN_EHWEB062302_M",
    "xsmid": "MULTI-PLAN_EHWEB062302_M",
    "language": "spanish",
    "robots": "noindex"
  }
}
</route>
