<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
import { AdsResponse, MediaAlphaAd } from '~/modules/ma'

const loading = ref(true)
const source = ref(0)
const ads = ref<MediaAlphaAd[]>([])
const isPaidUser = ref(true)

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  isPaidUser.value = getSessionEnhanced().isPaidUser
  source.value = 0

  const body = {
    data: {},
    ip: ip.lookup?.value,
    local_hour: new Date().getHours(),
    placement_id: 'loyhRoUY8koY5vZgUSMZtFFQKTVnPg',
    ua: navigator.userAgent,
    ua_language: navigator.language,
    url: location.href,
    version: 17,
    sub_3: getSession().id,
  }

  setTimeout(() => {
    source.value = stateConfig.value?.minOtcLtd
  }, 600)
  try {
    const url = import.meta.env.VITE_WEBSITE_API_URL + `/mediaalpha/ads`
    const response = await axios.post<AdsResponse>(url, body)
    ads.value = response?.data.ads
  } catch (e) {
    console.error('Error on retrieve ads', (e as any).toString())
  } finally {
    loading.value = false
  }
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

analytics.page({
  name: 'Mediaalpha ads Bridge',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    :hide-header="true"
    footer="2024"
    class="bg-white flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-start relative"
    ><span
      class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
    >
      Ad
    </span>
    <div class="container">
      <h2 class="text-4xl">
        Get {{ USDollarFormatter(0).format(output) }}/yr or more allowance
        benefit w some plans
      </h2>
      <div v-if="loading" class="p-8">
        <Spinner class="mx-auto" />
      </div>
      <MediaAlphaSingleAd
        v-for="(ad, index) in ads"
        :ad="ad"
        :key="ad.ad_id"
        :should-hide-star="index > 1"
      />
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}
.custom-transition {
  transition: width 0.5s;
}
</style>
