<script setup lang="ts">
defineProps<{
  partBPremium?: string
}>()

const hostname = ref<string>()

onMounted(() => {
  hostname.value = window.location.hostname
})
</script>

<template>
  <div class="container py-16 space-y-8">
    <div class="flex items-center space-x-6">
      <Logo class="mr-auto" />
      <img
        src="../assets/bbb-badge.svg"
        width="97"
        height="37"
        class="hidden sm:block"
      />
      <img
        src="../assets/comodo-badge.svg"
        width="107"
        height="34"
        class="hidden sm:block"
      />
    </div>
    <div class="text-gray-600">
      Disclaimer: The information on this site is not intended or implied to be
      a substitute for professional financial and/or medical advice. All
      content, including text, graphics, images and information, contained on or
      available through this web site is for general information purposes only.
      The information and materials contained in these pages and the terms,
      conditions and descriptions that appear, are subject to change without
      notice. Copyright © {{ new Date().getFullYear() }} {{ brand.url }} | All
      rights reserved |
      {{
        brand.address ||
        '8605 Santa Monica Blvd, PMB 38903, West Hollywood, CA 90069'
      }}
    </div>
  </div>
</template>
