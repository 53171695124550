export const routeBenefits = ref<string[] | null>(null)

export const getGivebackSummaryV2 = async ({
  zip_code,
  plan_type,
  benefit_type = 'giveback',
}: {
  zip_code: string
  plan_type?: string
  benefit_type?: string
}) => {
  let url =
    import.meta.env.VITE_WEBSITE_API_URL +
    `/benefits/v2/summary?zip_code=${zip_code}&benefit=${benefit_type}`
  if (plan_type) {
    url += `&plan_type=${plan_type}`
  }
  const { data } = await axios.get(url)
  return data?.summary
}
