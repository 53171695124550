<template>
  <div class="text-3xl sm:text-4xl text-center text-red">
    Congratulations!
  </div>

  <div class="text-2xl sm:text-3xl text-center">
    Speak with a licensed insurance agent.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />
</template>
