<script setup lang="ts">
const { value: getYourAllowanceYet } = useField('getYourAllowanceYet')
</script>

<template>
  <div class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1">
    <Button
      type="submit"
      variant="undefined"
      @click="getYourAllowanceYet = false"
      :class="[
        'button w-full h-12 bg-red-500 text-white border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
        'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
        'transition-all duration-150 ',
      ]"
    >
      No
    </Button>
    <Button
      type="submit"
      variant="undefined"
      @click="getYourAllowanceYet = true"
      :class="[
        'button w-full h-12 bg-green-500 text-white border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
        'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
        'transition-all duration-150 ',
      ]"
    >
      Yes
    </Button>
  </div>
</template>
