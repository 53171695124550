<script setup lang="ts">
useHead({
  title: `Additional Benefits OTC - ${brand.name}`,
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: USDollarFormatter(0).format(state.minOtc),
      minOtcLtd: USDollarFormatter(0).format(state.minOtcLtd),
    }
  }
})

provide('state', state)
enterExperiment('additionalBenefitsOtc2024Image')

const isMobile = useIsMobile()
const containerClass = computed(() => ({
  'px-2 py-3': isMobile,
  'px-4 py-6': !isMobile
}))

// Add this to know if user came from Google or Facebook
const isFromFacebookOrGoogle = computed(() => {
  return query.utm_source?.toLowerCase() === 'facebook' ||
         query.utm_source?.toLowerCase() === 'google'
})

// Use standard isPaidUser from session
const isPaidUser = ref(false)

onMounted(() => {
  // Get isPaidUser from session, matching standard pattern
  isPaidUser.value = getSessionEnhanced().isPaidUser
  
  // Preload both images to prevent flickering
  const preloadOrganic = new Image()
  preloadOrganic.src = '../../assets/buyback-couple-swimming.png'
  
  const preloadPaid = new Image()
  preloadPaid.src = '../../assets/otc-receipt.jpg'
})

// Add auto-scrolling feature - reference just the form element
const formRef = ref(null)
provide('formRef', formRef)
</script>

<template>
  <Layout header="simple3" footer="2025" class="container" :hideHeader="isMobile">
    <template #banner>
      <Banner v-if="!isMobile">
        On Medicare and Medicaid? *Available Medicare Advantage D-SNP
      </Banner>
    </template>

    <div class="max-w-3xl mx-auto space-y-3 py-2" :class="containerClass">
      <h2 class="text-xl sm:text-2xl text-center font-bold font-dm">
        Compare 2025 Medicare Advantage Plans with Over the Counter Allowance Benefits Allowance Benefits with plans from carriers like Humana & UnitedHealthcare®
      </h2>
      
      <!-- Conditional image using v-show for smooth transition -->
      <div class="relative">
        <img
          v-show="isPaidUser"
          src="../../assets/otc-receipt.jpg"
          width="1200"
          height="627"
          class="w-full mx-auto rounded-lg"
          alt="Medicare OTC/FLEX Card receipt from pharmacy showing coverage for over-the-counter items"
        />
        <img 
          v-show="!isPaidUser"
          src="../../assets/buyback-couple-swimming.png"
          width="1200"
          height="627"
          class="w-full mx-auto rounded-lg"
          alt="Happy Senior couple dancing at the sunset in the beach"
        />
      </div>
      
      <div class="shadow-md rounded bg-white overflow-hidden">
        <Form
          id="otc2024"
          class="!max-w-full p-4 sm:p-6"
          scrollBehavior="auto"
          ref="formRef"
          :steps="[
            { id: 'medicare' },
            { id: 'medicaid' },
            { id: 'otcallowance' },
            { id: 'loading' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
      
      <div class="text-lg text-center">
        Shop Medicare Advantage plans that may include a spending allowance for
        common plan-approved OTC items.
      </div>
      
      <div class="text-base text-gray-500 text-center px-2">
        Not all plans offer all of these benefits. Benefits may vary by carrier
        and location. Limitations and exclusions may apply.
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "smid": {
      "m10": "MULTI-PLAN_HLWEB1024272_M",
      "bh": "MULTI-PLAN_HLWEB0624191_M"
    }
  }
}
</route>
