<script setup lang="ts">
const { value: haveMedicare } = useField('haveMedicare')
const formRef = inject('formRef')

// No scrolling on first step - removed the scrolling code
</script>

<template>
  <div
    class="text-lg sm:text-xl text-red text-center font-bold mb-6 flex flex-col items-center justify-center gap-2"
  >
    See if you are eligible
    <Icon i-heroicons-outline:arrow-down class="w-6 h-6 text-red" />
  </div>

  <div class="text-2xl sm:text-3xl text-center font-bold mb-8"> 
    Are you currently enrolled in Medicare? 
  </div>

  <div class="grid grid-cols-2 gap-4 max-w-md mx-auto">
    <Button 
      wide 
      size="lg" 
      type="submit" 
      variant="darkBlue"
      @click="haveMedicare = true" 
      class="font-bold text-xl sm:text-2xl"
    >
      YES
    </Button>
    <Button 
      wide 
      size="lg" 
      type="submit" 
      variant="darkBlue"
      @click="haveMedicare = false" 
      class="font-bold text-xl sm:text-2xl"
    >
      NO
    </Button>
    <Button
      wide
      size="lg"
      type="submit"
      variant="gray"
      class="col-span-2 font-medium text-xl"
      @click="haveMedicare = null"
    >
      SKIP
    </Button>
  </div>
</template> 