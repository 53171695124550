<script setup lang="ts">
import { AdsResponse, MediaAlphaAd } from '~/modules/ma'
import { PlanRowView } from '~/modules/plan'

const props = defineProps<{
  planCardVersion: 1 | 2 | 3
  plans: PlanRowView[]
  insertAdAt?: number
  showCallButton?: boolean
}>()

const insertAdAtEach = computed(() => props.insertAdAt ?? 2)
const ads = ref<MediaAlphaAd[]>([])

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy()

  const body = {
    data: {},
    ip: ip.lookup?.value,
    local_hour: new Date().getHours(),
    placement_id: 'loyhRoUY8koY5vZgUSMZtFFQKTVnPg',
    ua: navigator.userAgent,
    ua_language: navigator.language,
    url: location.href,
    version: 17,
    sub_2: 'segment anonymous id value',
    sub_3: getSession().id,
  }

  try {
    let url = import.meta.env.VITE_WEBSITE_API_URL + `/mediaalpha/ads`
    const response = await axios.post<AdsResponse>(url, body)
    ads.value = response?.data.ads
  } catch (e) {
    console.error('Error on retrieve ads', (e as any).toString())
  }
})
</script>

<template>
  <div v-for="(plan, index) in plans" :key="plan.plan_id">
    <PlanCard
      v-if="planCardVersion === 1"
      :plan="plan"
      :is-expandable="true"
      :show-call-button="showCallButton ?? true"
    />
    <PlanCardV2
      v-else-if="planCardVersion === 2"
      :plan="plan"
      :is-expandable="true"
    />
    <PlanCardV3 v-else :plan="plan" :show-call-button="showCallButton" />
    <MediaAlphaSingleAd
      v-if="(index + 1) % insertAdAtEach === 0"
      :ad="ads.shift()"
    />
  </div>
</template>
